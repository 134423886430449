// useGlobalState.js
import { useState, useEffect } from 'react';

const useGlobalState = () => {
  const [globalState, setGlobalState] = useState({});
 
  useEffect(() => {
    // Get UTM parameters from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = {
      utmTerm: urlParams.get('utm_term'),
      utmSource: urlParams.get('utm_source'),
      utmMedium: urlParams.get('utm_medium'),
      utmCampaign: urlParams.get('utm_campaign'),
      utmContent: urlParams.get('utm_content'),
    };

    localStorage.setItem('utmTerm', utmParams.utmTerm);
    localStorage.setItem('utmSource', utmParams.utmSource);
    localStorage.setItem('utmMedium', utmParams.utmMedium);
    localStorage.setItem('utmCampaign', utmParams.utmCampaign);
    localStorage.setItem('utmContent', utmParams.utmContent);

    // Set the UTM parameters to the global state
    setGlobalState(utmParams);
  }, []);

  return globalState;
};

export default useGlobalState;
