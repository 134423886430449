import React, { useEffect, useState } from 'react';
import logo from './assets/img/logo192.png';
import arrowImg  from './assets/img/ctrl-left.svg';
import desk_bg from './assets/img/desk_bg.png';
import dek_1 from './assets/img/dek_1.png';
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import TagManager from 'react-gtm-module';
import useGlobalState from './Components/UseGlobalState/UseGlobalState';

import HomePage from "./Pages/HomePage";
import GamePage from "./Pages/GamePage";
import FormPage from "./Pages/FormPage";
import TrumaPage from "./Pages/TrumaPage";
import TakanonPage from "./Pages/TakanonPage";
import FaqPage from "./Pages/FaqPage";
import PricesPage from "./Pages/PricesPage";
import './reset.css';
import './App.css';

const tagManagerArgs = {
  gtmId: 'GTM-5QS2J449'
}
TagManager.initialize(tagManagerArgs)
function App() {
  const utmParams = useGlobalState();
  const [tel, setTel] = useState('');
  const [isSmsSent, setIsSmsSent] = useState(false);


  useEffect(() => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    
    if (isIOS) {
      document.body.classList.add('ie');
    } else {
      document.body.classList.remove('ie');
    }

    // Clean up the class on component unmount (optional)
    return () => {
      document.body.classList.remove('ie');
    };
  }, []);

  const handleSubmitSMS = async (e) => {
    TagManager.dataLayer({ dataLayer: { event: "desktop_sms" } })
    // console.log("DATA:", data);
    e.preventDefault();
    const dataSend = {
      phone: tel,
    };

    try {
      let res = await fetch("https://milka-july-2023.activated.digital/sendSMSdesktop.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(dataSend),
      });

      let response = res;
       console.log('res: ', res);
       console.log('res: ', typeof res);

       // Check if 'status: 200' exists in the object
    if (response.status === 200) {
        // console.log('Status: 200 exists in the object.');
        setIsSmsSent(true);
        alert('הקישור נשלח אליך לטלפון');
        setTel('');
    } else {
        // console.log('Status: 200 does not exist in the object.');
        setIsSmsSent(false);
        alert('אנא בדוק מספר טלפון שלך');
    }
   
    //   let resJson = await res.json();
    //   if (res.status === 200) {
    //     console.log("Sent");
    //     setIsSmsSent(true);
    //   } else {
    //     console.log("Not Sent");
    //     setIsSmsSent(false);
    //   }
    } catch (err) {
      console.log(err);
    }
};


  return (
    <main className="App">
      <BrowserView className='ac-desktop'>
          <img src={desk_bg} className="av-ldesk_bg" alt="logo" />
          <img src={dek_1} className="av-dek_1" alt="logo" />
          <div className="ac-dektop-form">
            <span className="ac-desk-form-ttl">
            נחשו כמה חבילות מילקה יש בחדר<br/>
            <strong>ותוכלו לזכות בכרטיס זוגי לפסטיבל השוקולד באיטליה, עם עוד מלא פינוקים בדרך!</strong>
            </span>
              <form onSubmit={handleSubmitSMS}>
                <div className="form-group required-control">
                    <label  className="control-label" for="text_2">טלפון</label>
                    <input type="tel" id="text_2" name="text_2" value={tel}  onChange={(e)=>setTel(e.target.value)} data-alias="" minlength="10" maxlength="10" placeholder="" className="form-control" required/>
                </div>
                <button type="submit" className="ac-btn">
                  <span>שלחו לי <span className="ac-en-font-bold">SMS</span></span>
                  <img src={arrowImg} className="ac-img-responsive ac-arrow" alt="לשליחת SMS יש ללחוץ כאן" />
                </button>
              </form>
          </div>
          <span>גרסה הזאת של מילקה זמינה במובייל בלבד</span>
      </BrowserView>
      <MobileView  className='ac-mobile'>
          <Router>
            <Routes> 
          
               <Route exact path="/" element={<HomePage/>} />
                <Route path="/game" element={<GamePage/>} />
                <Route path="/form" element={ <FormPage/>} />
                <Route path="/truma" element={<TrumaPage/>} />
                <Route path="/takanon" element={<TakanonPage/>} />
                <Route path="/faq" element={<FaqPage/>} />
                <Route path="/prices" element={<PricesPage/>} />
           
               
            </Routes>
          </Router> 
      </MobileView>
    
    </main>
  );
}

export default App;
