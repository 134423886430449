import React, { useState } from "react";
import { Menu } from '../Components/Menu/Menu.js';
import { useNavigate  } from 'react-router-dom'; // Assuming you are using React Router
import TagManager from "react-gtm-module";

// Iport images
import  milkalogohome  from '../assets/img/milkalogohome.svg';
import  homeImg  from '../assets/img/home.jpg';
import  arrowImg  from '../assets/img/ctrl-left.svg';
import  popuphome  from '../assets/img/popuphome.png';

// Iport css
import '../assets/css/home.css';



export default function HomePage() {
    
    const [startPopup, setStartPopup] = useState(false);
    const navigate = useNavigate();

    const openHomePopup = () =>{
        // console.log('clicked');
        TagManager.dataLayer({ dataLayer: { event: "enter_room" } })
        // document.body.classList.add('ac-filter-blur');
        setStartPopup(true);
    }

    const startGame = () => {
        TagManager.dataLayer({ dataLayer: { event: "start" } })
        // document.body.classList.remove('ac-filter-blur');
        navigate('/game');
    }
    return (
        <div className="ac-page ac-home-page">
            <Menu />
            <div className="ac-container">
            <img src={milkalogohome} className="ac-img-responsive ac-logo" alt="logo milka" />
                <img src={homeImg} className="ac-img-responsive ac-festiv-milka" alt="festimilka" />
                <span className="ac-text">
                נחשו כמה חפיסות שוקולד מילקה מסתתרות <br/>
בחדר <strong>ותוכלו לזכות בכרטיס זוגי לפסטיבל <br/>
השוקולד באיטליה, ועוד עשרות פרסים נוספים!</strong>
                </span>
                <button onClick={() => openHomePopup()} className="ac-btn ac-btn-home">
                    <span>כנסו לחדר</span>
                    <img src={arrowImg} className="ac-img-responsive ac-arrow" alt="כנסו לחדר" />
                </button>
                {startPopup ? (
                    <div className="ac-inner-page-home">
                        <div className="ac-overlay"></div>
                        <div className="ac-popup">
                            <span className="ac-title">
                               יש לכם 30 שניות,<br/> 
                               נחשו כמה שיותר מהר
                            </span>
                            <img src={popuphome} className="ac-img-responsive ac-popuphome" alt="כנסו לחדר" />
                            <button onClick={() => startGame()} className="ac-btn ac-btn">
                                <span>שנתחיל?</span>
                                <img src={arrowImg} className="ac-img-responsive ac-arrow" alt="כנסו לחדר" />
                            </button>
                            <span className="ac-text">
                                <span className="ac-text-medium">*ממליצים לנגוס במילקה <br/>לריכוז מקסימלי</span>
                            </span>
                        </div>
                    </div>
                ) : ''}
            </div>
       

        </div >

    )
    
}